import React, { useState, useEffect} from 'react';
import axios from 'axios';

const FranceSlotGetPage = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [pid, setPid] = useState(0);
  const [logFile, setLogFile] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      // 发送网络请求，获取后端日志
    const fetchlogBody = {
        command: "fetch_log",
        token:apiKey,
        log_file: logFile
    }
    axios.post('http://92.119.127.236:5000/execute_command', fetchlogBody)
        .then(response => {
            setOutputText(response.data.log); // 更新日志
        })
        .catch(error => {
          console.error('Error fetching logs:', error);
        });
    }, 5000); // 每 5 秒拉取一次日志

    // 组件销毁时清除定时器
    return () => clearInterval(intervalId);
  }, [apiKey, logFile]); // 空数组表示只在组件挂载时执行一次

  const handleConfigUpload = async () => {
    try {
        const jsonObject = JSON.parse(inputText);
        const uploadconfigBody = {
            command: "set_config",
            token:apiKey,
            config_path: "/root/france_slot_bot/config/book_slot_auto_config.json",
            config_data:jsonObject
        }
        const response = await axios.post('http://92.119.127.236:5000/execute_command', uploadconfigBody);
        setOutputText('成功上传配置')
    } catch (error) {
        console.error('set config failed:', error);
    }
  };

  const handleStart = async () => {
    setOutputText('starting')
    try {
        const startBody = {
            command: "start_run",
            token:apiKey,
            working_dir: "/root/france_slot_bot/slot_service",
            entrypoint:['python3', 'slot_locker_broker.py']
        }
        const response = await axios.post('http://92.119.127.236:5000/execute_command', startBody);
        setPid(response.data.pid)
        setLogFile(response.data.log_file)
    } catch (error) {
        console.error('start run failed:', error);
    }
    setIsRunning(true);
  };

  const handleStop = async () => {
    try {
        const stopBody = {
            command: "stop_run",
            token:apiKey,
            pid: pid
        }
        const response = await axios.post('http://92.119.127.236:5000/execute_command', stopBody);
    } catch (error) {
        console.error('stop run failed:', error);
    }
    setIsRunning(false);
  };

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleOutputChange = (event) => {
    setOutputText(event.target.value);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>法签抢签控制器</h1>
      <a href="/function-selector" style={styles.link}>切换功能</a>
      <input 
        type="text"
        value={apiKey}
        onChange={handleApiKeyChange}
        placeholder="输入API密钥..."
        style={styles.input}
      />
      <textarea 
        style={styles.textarea}
        placeholder="配置内容..."
        value={inputText}
        onChange={handleInputChange}
      ></textarea>
      <div style={styles.buttonContainer}>
        <button style={styles.buttonConfig} onClick={handleConfigUpload} >下发配置</button>
        <div style={styles.buttonSpacer}></div>
        <button style={isRunning ? styles.buttonDisabled : styles.buttonStart} onClick={handleStart} disabled={isRunning}>启动抢签</button>
        <div style={styles.buttonSpacer}></div>
        <button style={!isRunning ? styles.buttonDisabled : styles.buttonStop} onClick={handleStop} disabled={!isRunning}>停止抢签</button>
      </div>
      <textarea 
        style={styles.textarea}
        placeholder="日志内容..."
        value={outputText}
        onChange={handleOutputChange}
      ></textarea>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  title: {
    marginBottom: '20px',
    fontSize: '24px',
  },
  input: {
    width: '80%',
    height: '40px',
    marginBottom: '20px',
    fontSize: '16px',
    padding: '10px',
    border: '2px solid #ccc',
    borderRadius: '5px',
    outline: 'none',
  },
  textarea: {
    width: '80%',
    height: '150px',
    marginBottom: '20px',
    fontSize: '16px',
    padding: '10px',
    border: '2px solid #ccc',
    borderRadius: '5px',
    outline: 'none',
    fontFamily: 'monospace', // 使用等宽字体以提高可读性
    whiteSpace: 'pre-wrap', // 自动换行
    overflowWrap: 'break-word', // 单词断行
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  buttonConfig: {
    width: '100px',
    height: '40px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: '#fff',
    outline: 'none',
    transition: 'background-color 0.3s ease', // 添加过渡效果
  },
  buttonStart: {
    width: '100px',
    height: '40px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: '#fff',
    outline: 'none',
    transition: 'background-color 0.3s ease', // 添加过渡效果
  },
  buttonStop: {
    width: '100px',
    height: '40px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: '#fff',
    outline: 'none',
    transition: 'background-color 0.3s ease', // 添加过渡效果
  },
  buttonSpacer: {
    width: '20px', // 调整按钮间距
  },
  buttonDisabled: {
    width: '100px',
    height: '40px',
    fontSize: '16px',
    cursor: 'not-allowed',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#ccc',
    color: '#666',
    outline: 'none',
  },
};

export default FranceSlotGetPage;
