import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserConfigPage.css';
import CustomDialog from './CustomDialog';

const ConfigManager = () => {
  const [key, setKey] = useState('mainConfig');
  const [config, setConfig] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [configurations, setConfigurations] = useState([]);

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const showDialog = (title, message) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogOpen(true);
  };

  const fetchConfigurations = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        'http://romi.one:8080/common/api/dynamic-configurations',
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setConfigurations(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('获取配置列表失败', error);
        showDialog('获取配置列表失败', error.response?.data?.message || error.message);
      }
    }
  };

  const handleConfigSelect = (e) => {
    const selectedKey = e.target.value;
    setKey(selectedKey);
    const selectedConfig = configurations.find(config => config.configKey === selectedKey);
    setConfig(selectedConfig ? selectedConfig.configValue : '');
  };

  const handleQuery = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `http://romi.one:8080/common/api/dynamic-configurations/key/${key}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setConfig(response.data.configValue || '');
      showDialog('查询配置成功', '配置内容已成功加载');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('查询配置失败', error);
        showDialog('查询配置失败', error.response?.data?.message || error.message);
      }
    }
  };

  const handleAddConfig = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        'http://romi.one:8080/common/api/dynamic-configurations',
        {
          configKey: key,
          configValue: config,
          description: '测试',
          type: 'string',
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        showDialog('新增配置成功', '配置已成功新增');
        fetchConfigurations();
      } else {
        showDialog('新增配置失败', response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('新增配置失败', error);
        showDialog('新增配置失败', error.response?.data?.message || error.message);
      }
    }
  };

  const handleUpdateConfig = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `http://romi.one:8080/common/api/dynamic-configurations/key/${key}`,
        {
          configKey: key,
          configValue: config,
          description: '正式',
          type: 'string',
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        showDialog('更新配置成功', '配置已成功更新');
        fetchConfigurations();
      } else {
        showDialog('更新配置失败', response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('更新配置失败', error);
        showDialog('更新配置失败', error.response?.data?.message || error.message);
      }
    }
  };

  const handleDeleteConfig = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.delete(
        `http://romi.one:8080/common/api/dynamic-configurations/key/${key}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        showDialog('删除配置成功', '配置已成功删除');
        fetchConfigurations();
      } else {
        showDialog('删除配置失败', response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('删除配置失败', error);
        showDialog('删除配置失败', error.response?.data?.message || error.message);
      }
    }
  };

  return (
    <div className="config-manager">
      <div className="input-section">
        <select onChange={handleConfigSelect} className="config-dropdown">
          {configurations.map((config) => (
            <option key={config.configKey} value={config.configKey}>
              {config.configKey}
            </option>
          ))}
        </select>
      </div>
      <div className="actions-section">
        <input
          type="text"
          placeholder="输入键值"
          value={key}
          onChange={(e) => setKey(e.target.value)}
          className="key-input"
        />
        <button onClick={handleQuery} className="query-button">
          查询配置
        </button>
        <button onClick={handleAddConfig} className="add-button">
          新增配置
        </button>
        <button onClick={handleUpdateConfig} className="update-button">
          更新配置
        </button>
        <button onClick={handleDeleteConfig} className="delete-button">
          删除配置
        </button>
      </div>
      <textarea
        value={config}
        onChange={(e) => setConfig(e.target.value)}
        className="config-textarea"
        placeholder="配置内容"
      />
      <CustomDialog
        isOpen={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        message={dialogMessage}
      />
    </div>
  );
};

export default ConfigManager;
