import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import './UserManagementPage.css'; // 引入样式文件
import CustomDialog from './CustomDialog';



const TLSUserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // 跟踪选中的用户
  const [selectedUser, setSelectedUser] = useState(null); // 用于编辑用户信息
  const [isEditing, setIsEditing] = useState(false); // 是否处于编辑模式
  const [isAdding, setIsAdding] = useState(false); // 是否处于新增模式
  const [page, setPage] = useState(0); // 当前页码，默认为第一页
  const [totalPages, setTotalPages] = useState(1); // 总页数，默认为1
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const usersCacheRef = useRef({}); // 使用 useRef 定义持久化引用
  const [editorContent, setEditorContent] = useState("");

  const handleEditorChange = (e) => {
    setEditorContent(e.target.value);
  };

  useEffect(() => {
    fetchUsers();
  }, [searchTerm, page]); // 当页码改变时重新获取用户数据


  const handleAddUserbyGpt = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        "http://92.119.127.236:8080/tls-visa/api/create-users-by-gpt",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
             Authorization: token,
          },
          body: JSON.stringify({ content: editorContent }),
        }
      );
      
      if (response.ok) {
        setDialogContent('添加客户成功');
        setDialogOpen(true);
      } else {
        //alert("用户创建失败");
        setDialogContent('Failed to add user. Please try again later.');
      }
    } catch (error) {
      //alert("请求失败: " + error.message);
      setDialogContent('Failed to add user. Please try again later.');
    }
  };

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token'); // 从本地存储中获取token
      const response = await axios.get(`http://romi.one:8080/tls-visa/api/users?accountName=${searchTerm}&page=${page}&size=15`, {
        headers: {
          Authorization: token // 将token添加到请求头中
        }
      });
      setTotalPages(response.data.totalPages)
      setUsers(response.data.content);

      for (const user of response.data.content) {
        usersCacheRef[user['id']] = user
      }
      console.log(usersCacheRef)
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1); // 下一页
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1); // 上一页
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  
  const handleStatus = async (user) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`http://romi.one:8080/tls-visa/api/users/appointments?uid=${user.id}`, {
        headers: {
          Authorization: token,
        },
      });

      if (response.data.length > 0) {
        let message = `用户 ${user.lastName} ${user.firstName} 的预约数量: ${response.data.length}\n\n`;

        response.data.forEach((appointment, index) => {
          const { bookDate, cancelStatus } = appointment;
          const formattedDate = formatDateDetail(bookDate);
          const statusText = cancelStatus === 0 ? "已预订" : "已取消";
          message += `预约 ${index + 1}:\n日期: ${formattedDate}\n状态: ${statusText}\n\n`;
        });

        setDialogContent(message);
        setDialogOpen(true);
      } else {
        setDialogContent('未找到该用户的预约信息。');
        setDialogOpen(true);
      }
    } catch (error) {
      console.error('Error fetching status:', error);
      setDialogContent('获取状态时出错，请稍后再试。');
      setDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveUser = async (updatedUser) => {
    try {
      if (isAdding) {
        // 新增用户
        const token = localStorage.getItem('token'); // 从本地存储中获取token
        await axios.post(`http://romi.one:8080/tls-visa/api/users`, updatedUser, {
          headers: {
            Authorization: token // 将token添加到请求头中
          }
        });
        // 新增成功，显示成功提示
        alert('添加客户成功');
      } else {
        const token = localStorage.getItem('token'); // 从本地存储中获取token
        const response = await axios.put(`http://romi.one:8080/tls-visa/api/users/${updatedUser.id}`, updatedUser, {
          headers: {
            Authorization: token // 将token添加到请求头中
          }
        });
        console.log('User updated:', response.data);
      }
      setIsEditing(false);
      setSelectedUser(null);
      setIsAdding(false);
      fetchUsers(); // 保存后刷新用户列表
    } catch (error) {
      console.error('Error saving user:', error);
      alert('Failed to save user. Please try again later.');
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setSelectedUser(null);
  };
  

  const handleCheckboxChange = (userId) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    if (selectedIndex === -1) {
      setSelectedUsers([...selectedUsers, userId]); // 添加到选中列表
    } else {
      setSelectedUsers(selectedUsers.filter(id => id !== userId)); // 从选中列表中移除
    }
  };

  const handleAddUser = () => {
    console.log('Adding a new user...');
    setIsAdding(true);
    setSelectedUser(
      {
        centerName: '伦敦',
        username: '',
        password: '',
        // phone: '',
        expectedVisaSubmitStart: formatDate(new Date().toISOString()),
        expectedVisaSubmitEnd: formatDate(new Date().toISOString()),
        // extinfo:"{\r\n  \"browser_id\": 1,\r\n  \"center_name\": \"TlsFranceUK_London_Short_Stay\",\r\n  \"support_services\": [\r\n    \"short_stay\"\r\n  ]\r\n}",
        priority:0,
        status: '未完成',
        note: '',
        socialAccount:'',
        // appointment:''
      }
    ); // 清空选中用户数据
    setIsEditing(true);
  };

  const handleExport = () => {
    // 将ISO日期转换为MM/DD/YYYY格式的函数
    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    };
    // 将原始数据转换为所需格式
    const exportedUsers = selectedUsers.map(userId => {
      const user = usersCacheRef[userId];
      return {
        id: user.id,
        enable: 1,
        birth_date: formatDate(user.birthday),
        email: user.email,
        phone: user.phone,
        first_name: user.firstName,
        last_name: user.lastName
      };
    });
  
    // 输出转换后的数据
    console.log('Exported users:', exportedUsers);

    const data = JSON.stringify(exportedUsers, null, 2);
  
    const blob = new Blob([data], { type: 'application/json' });
    saveAs(blob, 'data.json');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${year}-${month}-${day}`;
  };

  function getStatusText(status) {
    switch (status) {
      case 0:
        return '未完成';
      case 1:
        return '已完成';
      case 2:
        return '已取消';
      default:
        return '未知状态';
    }
  }

  const formatDateDetail = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    const hours = date.getHours().toString().padStart(2, '0'); // 将小时补齐为两位数
    const minutes = date.getMinutes().toString().padStart(2, '0'); // 将分钟补齐为两位数
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteUser = async (userId) => {
    const token = localStorage.getItem('token'); // 获取token
    if (!window.confirm("确定要删除此用户吗？")) return;
  
    try {
      await axios.delete(`http://romi.one:8080/tls-visa/api/users/${userId}`, {
        headers: {
          Authorization: token,
        },
      });
      alert("用户删除成功！");
      // 从用户列表中移除被删除的用户
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      console.error('删除用户失败:', error);
      alert('删除用户失败，请重试。');
    }
  };

  return (
    <div>
      <div className="container">
      <h2 className="title-style">TLS用户管理</h2>
      <div className="content-wrapper">
        <div className="left-container">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by social account..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="button-container">
            <button onClick={handleAddUser}>新增用户</button>
          </div>
        </div>

        <div className="right-container">
          <div className="search-container">
            <textarea
              placeholder="请输入用户信息..."
              value={editorContent}
              onChange={handleEditorChange}
              rows={1}
              style={{
                resize: "none",
                overflow: "hidden",
                width: "100%",
              }}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            />
          </div>
          <div className="button-container">
            <button onClick={handleAddUserbyGpt}>新增用户 by GPT</button>
          </div>
        </div>
      </div>
    </div>
      {isEditing ? (
        <UserForm user={selectedUser} onSave={handleSaveUser} onCancel={handleCancelEdit} />
      ) : (
        <div>
          <table className="user-table"> {/* 添加类名 */}
            <thead>
              <tr>
                <th>选择</th> {/* 添加选中列 */}
                <th>ID</th>
                <th>递签中心</th>
                <th>社交账户</th>
                <th>用户名</th>
                <th>密码</th>
                {/* <th>电话📱</th> */}
                <th>期望递签日期📅</th>
                <th>优先级</th>
                {/* <th>预约信息</th> */}
                <th>状态</th>
                {/* <th>配置</th> */}
                <th>备注📓</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(user.id)}
                      checked={selectedUsers.includes(user.id)}
                    />
                  </td>
                  <td>{user.id}</td>
                  <td>{user.centerName}</td>
                  <td>{user.socialAccount}</td>
                  <td>{user.username}</td>
                  <td>{user.password}</td>
                  {/* <td>{user.phone}</td> */}
                  <td>{formatDate(user.expectedVisaSubmitStart)}至<br></br>{formatDate(user.expectedVisaSubmitEnd)}</td>
                  <td>{user.priority}</td>
                  {/* <td>{user.appointment}</td> */}
                  <td>{getStatusText(user.status)}</td>
                  {/* <td>{user.extinfo}</td> */}
                  <td>{user.note || '无'}</td>
                  <td>
                  <div className="button-container2">
                    <button onClick={() => handleEditUser(user)}>编辑</button>
                    <button onClick={() => handleDeleteUser(user.id)}>删除</button>
                  </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="button-container">
            <button onClick={handlePrevPage} disabled={page === 0}>上一页</button>
            <button onClick={handleNextPage} disabled={page === totalPages - 1}>下一页</button>
            <button onClick={handleExport}>导出</button> {/* 添加导出按钮 */}
          </div>
        </div>
      )}
    </div>
  );
};

const UserForm = ({ user, onSave, onCancel }) => {
  // 使用对象的解构和对象展开运算符设置默认值
  const [formData, setFormData] = useState({ ...user, bookingStatus: user ? user.bookingStatus : '未完成',
    expectTime: user.expectTime });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Name:', name);  // 应输出 'expectTime'
    console.log('Value:', value);  // 应输出选中的时间值
    // 使用对象展开运算符确保保留用户未编辑的字段
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  useEffect(() => {
    const textarea = document.querySelector('textarea[name="extinfo"]');
    if (textarea) {
      textarea.style.height = 'auto'; // 重置高度
      textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容设置高度
    }
  }, [formData.extinfo]); // 监听规则内容的变化

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${year}-${month}-${day}`;
  };

  return (
    <div className='form-container'>
      <form onSubmit={handleSubmit}>
        <label>
          递签中心:
          <input type="text" name="centerName" value={formData.centerName} onChange={handleChange} />
        </label>
        <label>
          社交账户:
          <input type="text" name="socialAccount" value={formData.socialAccount} onChange={handleChange} />
        </label>
        <label>
          用户名:
          <input type="text" name="username" value={formData.username} onChange={handleChange} />
        </label>
        <label>
          密码:
          <input type="text" name="password" value={formData.password} onChange={handleChange} />
        </label>
        {/* <label>
          电话:
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
        </label> */}
        <label>
          期望开始递签日期:
          <input type="date" name="expectedVisaSubmitStart" value={formatDate(formData.expectedVisaSubmitStart)} onChange={handleChange} />
        </label>
        <label>
          期望结束递签日期:
          <input type="date" name="expectedVisaSubmitEnd" value={formatDate(formData.expectedVisaSubmitEnd)} onChange={handleChange} />
        </label>
        <label>
          优先级:
          <input
            type="number"
            name="priority"
            value={formData.priority || 0} // 默认值为0
            onChange={handleChange}
            step="1"
            min="0"
          />
        </label>
        <label>
          订单状态:
          <select name="status" value={formData.status} onChange={handleChange}>
            <option value="0">未完成</option>
            <option value="1">已完成</option>
            <option value="2">已取消</option>
          </select>
        </label>
        {/* <label>
          配置:
          <textarea
            name="extinfo"
            value={formData.extinfo}
            onChange={handleChange}
            rows={1}
            style={{
              resize: 'none',
              overflow: 'hidden',
              width: '100%',
            }}
            onInput={(e) => {
              e.target.style.height = 'auto'; // 每次输入时先重置高度
              e.target.style.height = `${e.target.scrollHeight}px`; // 设置为内容的高度
            }}
          />
        </label> */}
        <label>
          备注:
          <input type="text" name="note" value={formData.note} onChange={handleChange} />
        </label>
        <button className="custom-button" type="submit">保存</button>
        &nbsp;&nbsp;&nbsp; {/* 插入一个空格 */}
        <button className="custom-button" type="button" onClick={onCancel}>取消</button>
      </form>
    </div>
  );
};


export default TLSUserManagementPage;
