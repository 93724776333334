import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './UserManagementPage.css'; // 引入样式文件
import CustomDialog from './CustomDialog'; // 引入自定义弹窗组件

const BookStatus = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0); // 当前页码，默认为第一页
  const [totalPages, setTotalPages] = useState(1); // 总页数，默认为1
  const [searchTerm, setSearchTerm] = useState('');
  const [isUpdating, setIsUpdating] = useState(false); // 控制按钮禁用状态
  const [isDialogOpen, setIsDialogOpen] = useState(false); // 控制弹窗状态
  const [dialogContent, setDialogContent] = useState(''); // 弹窗内容

  const usersCacheRef = useRef({}); // 使用 useRef 定义持久化引用

  const fetchUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`http://92.119.127.236:8080/france-slot/api/appointment?page=${page}&size=15`, {
        headers: {
          Authorization: token,
        },
      });

      setTotalPages(response.data.totalPages);
      setUsers(response.data.content);

      // 缓存用户数据
      response.data.content.forEach((user) => {
        usersCacheRef.current[user.id] = user;
      });

      fetchUserSocialAccount(response.data.content);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('Error fetching users:', error);
      }
    }
  }, [page]);

  const fetchUserSocialAccount = useCallback(async (users) => {
    try {
      const token = localStorage.getItem('token');
  
      // 提取所有用户的ID
      const userIds = users.map(user => user.uid);
  
      // 发出请求，传入用户ID数组
      const response = await axios.post(`http://92.119.127.236:8080/france-slot/api/users/batch`,
        userIds, {
        headers: {
          Authorization: token,
        },
      });
  
      // 检查响应数据是否存在并处理
      if (response.data && Array.isArray(response.data)) {
        // 遍历所有用户，更新socialAccount信息
        const updatedUsers = users.map(user => {
          // 在response数据中找到对应的用户
          const responseUser = response.data.find(data => data.id === user.uid);
          
          // 如果找到匹配的用户，则更新socialAccount
          if (responseUser) {
            return {
              ...user,
              socialAccount: responseUser.socialAccount || null, // 若socialAccount不存在则设置为null
            };
          }
          
          // 如果没有找到匹配的用户，则保持原来的用户数据
          return user;
        });
  
        // 更新状态
        setUsers(updatedUsers);
      } else {
        console.error('Invalid response data:', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('Error fetching user social account:', error);
      }
    }
  }, []);
  
  const updateAppointmentStatus = useCallback(async () => {
    setIsUpdating(true); // 禁用按钮
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('http://92.119.127.236:8080/france-slot/api/update-appointment-status', {
        headers: {
          Authorization: token,
        },
      });
  
      const data = response.data;
      const canceledAppointments = [];
  
      if (Array.isArray(data)) {
        data.forEach((appointment) => {
          if (appointment.cancelStatus === 1) {
            canceledAppointments.push(`用户: ${appointment.name}, 预约日期: ${formatDate(appointment.bookDate)}`);
          }
        });
      }
  
      if (canceledAppointments.length > 0) {
        const message = `被取消的用户:\n${canceledAppointments.join('\n')}`;
        setDialogContent(message); // 设置弹窗内容
        setIsDialogOpen(true); // 打开弹窗
      }
  
      fetchUsers(); // 刷新用户数据
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('Error updating user social account:', error);
      }
    } finally {
      setIsUpdating(false); // 请求完成后重新启用按钮
    }
  }, [fetchUsers]);

  const closeDialog = () => {
    setIsDialogOpen(false); // 关闭弹窗
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const formatBookStatus = (status) => {
    return {
      text: status === 0 ? "已预订" : "已取消",
      color: status === 0 ? "green" : "red",
    };
  };

  return (
    <div>
      <h2 className="title-style">TROOV预约用户管理</h2>
      <button 
        onClick={updateAppointmentStatus} 
        disabled={isUpdating}
        className="btn btn-primary my-3"
      >
        {isUpdating ? '更新中...' : '更新预约状态'}
      </button>
      <div>
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>社交账号</th>
              <th>姓名</th>
              <th>邮箱</th>
              <th>预约链接🔗</th>
              <th>预约日期📅</th>
              <th>取消状态</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.socialAccount !== null ? user.socialAccount : ''}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  <a href={user.cancelUrl} target="_blank" rel="noopener noreferrer">
                    点击查看
                  </a>
                </td>
                <td>{formatDate(user.bookDate)}</td>
                <td style={{ color: formatBookStatus(user.cancelStatus).color }}>
                  {formatBookStatus(user.cancelStatus).text}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="button-container">
          <button onClick={handlePrevPage} disabled={page === 0}>上一页</button>
          <button onClick={handleNextPage} disabled={page === totalPages - 1}>下一页</button>
        </div>
      </div>

      {/* 自定义弹窗 */}
      <CustomDialog 
        isOpen={isDialogOpen} 
        onClose={closeDialog} 
        title="更新预约状态" 
        message={dialogContent} 
      />
    </div>
  );
};

export default BookStatus;
