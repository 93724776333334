import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import './UserManagementPage.css'; // 引入样式文件
import CustomDialog from './CustomDialog';



const UserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // 跟踪选中的用户
  const [selectedUser, setSelectedUser] = useState(null); // 用于编辑用户信息
  const [isEditing, setIsEditing] = useState(false); // 是否处于编辑模式
  const [isAdding, setIsAdding] = useState(false); // 是否处于新增模式
  const [page, setPage] = useState(0); // 当前页码，默认为第一页
  const [totalPages, setTotalPages] = useState(1); // 总页数，默认为1
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const usersCacheRef = useRef({}); // 使用 useRef 定义持久化引用
  const [dialogMessage, setDialogMessage] = useState(''); // 提示框消息内容
  const [dialogTitle, setDialogTitle] = useState(''); // 提示框标题
  const [appointments, setAppointments] = useState({}); // 用于存储每个用户的预约信息
  const [useExpectTime, setUseExpectTime] = useState(false);
  const searchTimeoutRef = useRef(null);  // 用于保存 timeout 引用
  const [editorContent, setEditorContent] = useState("");

  const handleToggle = () => {
    setUseExpectTime(prev => !prev);
  };

  const handleEditorChange = (e) => {
    setEditorContent(e.target.value);
  };

  const handleAddUserbyGpt = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        "http://92.119.127.236:8080/france-slot/api/create-users-by-gpt",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
             Authorization: token,
          },
          body: JSON.stringify({ content: editorContent }),
        }
      );
      
      if (response.ok) {
        setDialogContent('添加客户成功');
        setDialogOpen(true);
      } else {
        //alert("用户创建失败");
        setDialogContent('Failed to add user. Please try again later.');
      }
    } catch (error) {
      //alert("请求失败: " + error.message);
      setDialogContent('Failed to add user. Please try again later.');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [searchTerm, page]); // 当页码改变时重新获取用户数据

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token'); // 从本地存储中获取token
      const response = await axios.get(`http://92.119.127.236:8080/france-slot/api/users?accountName=${searchTerm}&page=${page}&size=15`, {
        headers: {
          Authorization: token // 将token添加到请求头中
        }
      });
      setTotalPages(response.data.totalPages)
      setUsers(response.data.content);

      for (const user of response.data.content) {
        usersCacheRef[user['id']] = user
      }

       // 提取所有用户的ID
       const userIds = response.data.content.map(user => user.id);
       // 获取用户的预约信息并存储
       const appointmentResponse = await axios.post(`http://92.119.127.236:8080/france-slot/api/book-dates`, userIds, {
         headers: {
           Authorization: token,
         },
       });
       // 更新预约信息状态
      if (appointmentResponse.data && typeof appointmentResponse.data === 'object') {
        // 创建一个新的预约信息对象
        const newAppointments = {};

        // 遍历返回的数据
        Object.entries(appointmentResponse.data).forEach(([userId, bookDate]) => {
          newAppointments[userId] = bookDate
            ? [{ date: formatDateDetail(bookDate), status: "已预订" }]
            : [{ status: "已取消" }];
        });

        // 更新状态
        setAppointments((prev) => ({
          ...prev,
          ...newAppointments,
        }));
      } else {
        console.error('Invalid appointment response data:', appointmentResponse.data);
      }
      
      
      console.log(usersCacheRef)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('Error fetching users:', error);
      }
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1); // 下一页
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1); // 上一页
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  
  const handleStatus = async (user) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`http://92.119.127.236:8080/france-slot/api/appointments?uid=${user.id}`, {
        headers: {
          Authorization: token,
        },
      });

      if (response.data.length > 0) {
        let message = `用户 ${user.lastName} ${user.firstName} 的预约数量: ${response.data.length}\n\n`;

        response.data.forEach((appointment, index) => {
          const { bookDate, cancelStatus } = appointment;
          const formattedDate = formatDateDetail(bookDate);
          const statusText = cancelStatus === 0 ? "已预订" : "已取消";
          message += `预约 ${index + 1}:\n日期: ${formattedDate}\n状态: ${statusText}\n\n`;
        });

        setDialogContent(message);
        setDialogOpen(true);
      } else {
        setDialogContent('未找到该用户的预约信息。');
        setDialogOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('Error fetching users:', error);
        setDialogContent('获取状态时出错，请稍后再试。');
        setDialogOpen(true);
      }
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveUser = async (updatedUser) => {
    try {
      if (isAdding) {
        // 新增用户
        const token = localStorage.getItem('token'); // 从本地存储中获取token
        await axios.post(`http://92.119.127.236:8080/france-slot/api/users`, updatedUser, {
          headers: {
            Authorization: token // 将token添加到请求头中
          }
        });
        setDialogContent('添加客户成功');
        setDialogOpen(true);
      } else {
        const token = localStorage.getItem('token'); // 从本地存储中获取token
        const response = await axios.put(`http://92.119.127.236:8080/france-slot/api/users/${updatedUser.id}`, updatedUser, {
          headers: {
            Authorization: token // 将token添加到请求头中
          }
        });
        console.log('User updated:', response.data);
      }
      setIsEditing(false);
      setSelectedUser(null);
      setIsAdding(false);
      fetchUsers(); // 保存后刷新用户列表
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // 如果返回状态码是401，则跳转到登录页面
        window.location.href = 'http://92.119.127.236/login';
      } else {
        console.error('Error saving user:', error);
        setDialogContent('Failed to save user. Please try again later.');
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setSelectedUser(null);
  };

  const handleCheckboxChange = (userId) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    if (selectedIndex === -1) {
      setSelectedUsers([...selectedUsers, userId]); // 添加到选中列表
    } else {
      setSelectedUsers(selectedUsers.filter(id => id !== userId)); // 从选中列表中移除
    }
  };

  const handleAddUser = () => {
    console.log('Adding a new user...');
    setIsAdding(true);
    setSelectedUser(
      {
        firstName: '',
        lastName: '',
        birthday: '2000-01-01',
        email: '', phone: '',
        expectedDeliveryDate: formatDate(new Date().toISOString()),
        bookingStatus: '未完成',
        priority: 0,
        rules: '0',
        note: '',
        socialAccount:''
      }
    ); // 清空选中用户数据
    setIsEditing(true);
  };

  const handleExport = () => {
    // 将ISO日期转换为MM/DD/YYYY格式的函数
    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    };
    // 定义时间数组
    const timeSlots = [
      "09:30", "09:45", "10:00", "10:15", "10:30", 
      "10:45", "11:00", "11:15", "11:30", "11:45", 
      "14:00", "14:15", "14:30", "14:45", "15:00"
    ];

    // 将原始数据转换为所需格式
    const exportedUsers = selectedUsers.map((userId, index) => {
      const user = usersCacheRef[userId];
      // 基础信息对象
      const userInfo = {
        id: user.id,
        enable: 1,
        birth_date: formatDate(user.birthday),
        email: user.email,
        phone: user.phone,
        first_name: user.firstName,
        last_name: user.lastName,
      };

      userInfo.importance = (index + 1) % 2 === 1 ? "vip" : "normal"; // 索引从0开始，因此+1
      
      // 插入 slot 信息
      userInfo.slot = {
        time: useExpectTime ? user.expectTime : timeSlots[index % timeSlots.length],
        rate: "0.00",
        capacity: 1,
        order: (index + 1) % 2 === 1 ? 0 : 1,
      };

      return userInfo;
    });

    const output = {
      version: Date.now(),
      data: exportedUsers
    };
    
  
    // 输出转换后的数据
    console.log('Exported users:', output);

    const data = JSON.stringify(output, null, 2);
  
    const blob = new Blob([data], { type: 'application/json' });
    saveAs(blob, 'data.txt');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${year}-${month}-${day}`;
  };

  const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${month}-${day}`;
  };

  //  // 显示提示信息的提示框
  //  const showDialog = (title, message) => {
  //   setDialogTitle(title);
  //   setDialogMessage(message);
  //   setDialogOpen(true);
  // };


  const formatDateDetail = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    const hours = date.getHours().toString().padStart(2, '0'); // 将小时补齐为两位数
    const minutes = date.getMinutes().toString().padStart(2, '0'); // 将分钟补齐为两位数
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    // 清除上一个搜索请求的延迟
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    // 延迟执行搜索，避免在每次输入时触发请求
    searchTimeoutRef.current = setTimeout(() => {
      setPage(0); // 重置为第一页
    }, 500);  // 500ms 防抖延迟时间，可以根据需要调整
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allUserIdsOnPage = users.map(user => user.id);
      setSelectedUsers(prevSelectedUsers =>
        [...new Set([...prevSelectedUsers, ...allUserIdsOnPage])]
      );
    } else {
      const allUserIdsOnPage = users.map(user => user.id);
      setSelectedUsers(prevSelectedUsers =>
        prevSelectedUsers.filter(id => !allUserIdsOnPage.includes(id))
      );
    }
  };
  
  const areAllSelected = () => {
    const allUserIdsOnPage = users.map(user => user.id);
    return allUserIdsOnPage.every(id => selectedUsers.includes(id));
  };
  

  return (
    <div>
      <div className="container">
      <h2 className="title-style">TROOV 用户管理</h2>
      <div className="content-wrapper">
        <div className="left-container">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by social account..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="button-container">
            <button onClick={handleAddUser}>新增用户</button>
          </div>
        </div>

        <div className="right-container">
          <div className="search-container">
            <textarea
              placeholder="请输入用户信息..."
              value={editorContent}
              onChange={handleEditorChange}
              rows={1}
              style={{
                resize: "none",
                overflow: "hidden",
                width: "100%",
              }}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            />
          </div>
          <div className="button-container">
            <button onClick={handleAddUserbyGpt}>新增用户 by GPT</button>
          </div>
        </div>
      </div>
    </div>
      {isEditing ? (
        <UserForm user={selectedUser} onSave={handleSaveUser} onCancel={handleCancelEdit} />
      ) : (
        <div>
          <table className="user-table"> {/* 添加类名 */}
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={areAllSelected()}
                  />
                </th> {/* 全选复选框 */}
                <th>ID</th>
                <th>社交账户</th>
                <th>姓名</th>
                <th>邮箱📮</th>
                <th>生日📅</th>
                <th>电话📱</th>
                <th>递签日期📅</th>
                <th>优先级👑</th>
                <th>约束规则📖</th>
                {/* <th>Slot时间📅</th> */}
                <th>预约信息</th> {/* 新增预约信息列 */}
                <th>订单状态</th>
                <th>备注📓</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(user.id)}
                      checked={selectedUsers.includes(user.id)}
                    />
                  </td>
                  <td>{user.id}</td>
                  <td>{user.socialAccount}</td>
                  <td>{user.lastName} {user.firstName}</td>
                  <td>{user.email}</td>
                  <td>{formatDate(user.birthday)}</td>
                  <td>{user.phone}</td>
                  <td>{formatDate2(user.expectedDeliveryDate)}</td>
                  <td>{user.priority}</td>
                  <td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    {user.rules}
                  </td>
                  {/* <td>{user.expectTime}</td> */}
                  <td>
                    {appointments[user.id] && appointments[user.id].length > 0 ? (
                      (() => {
                        const firstBookedAppointment = appointments[user.id].find(
                          (appointment) => appointment.status === '已预订'
                        );
                        return firstBookedAppointment ? (
                          <div className="green-text">
                            {firstBookedAppointment.date}
                          </div>
                        ) : (
                          '无预约信息'
                        );
                      })()
                    ) : (
                      '无预约信息'
                    )}
                  </td>
                  <td>{user.bookingStatus}</td>
                  <td>{user.note || '无'}</td>
                  <td>
                    <div className="button-container2">
                      <div>
                        <button className="custom-button" onClick={() => handleStatus(user)}>状态</button>
                        <CustomDialog isOpen={isDialogOpen} onClose={closeDialog} title="操作状态" message={dialogContent} />
                      </div>
                      <button className="custom-button" onClick={() => handleEditUser(user)}>编辑</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="user-export-container">
              <TimeSelectionToggle onToggle={handleToggle} isChecked={useExpectTime} />
          </div>
          <div className="button-container">
            <button onClick={handlePrevPage} disabled={page === 0}>上一页</button>
            <button onClick={handleNextPage} disabled={page === totalPages - 1}>下一页</button>
            <button onClick={handleExport}>导出</button> {/* 添加导出按钮 */}
          </div>
        </div>
      )}
    </div>
  );  
};

// 选择框组件
const TimeSelectionToggle = ({ onToggle, isChecked }) => {
  return (
    <label className="toggle-label">
      <input type="checkbox" className="toggle-checkbox" checked={isChecked} onChange={onToggle} />
      <span className="toggle-slider"></span>
      使用自定义递签时间点导出
    </label>
  );
};

const UserForm = ({ user, onSave, onCancel }) => {
  // 使用对象的解构和对象展开运算符设置默认值
  const [formData, setFormData] = useState({ ...user, bookingStatus: user ? user.bookingStatus : '未完成',
    expectTime: user.expectTime });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Name:', name);  // 应输出 'expectTime'
    console.log('Value:', value);  // 应输出选中的时间值
    // 使用对象展开运算符确保保留用户未编辑的字段
    setFormData(prevData => ({
      ...prevData,
      [name]: name === "priority" ? parseInt(value, 10) : value, // 将 priority 转换为整数
    }));
  };

  // 当组件加载时自动调整 <textarea> 高度
  useEffect(() => {
    const textarea = document.querySelector('textarea[name="rules"]');
    if (textarea) {
      textarea.style.height = 'auto'; // 重置高度
      textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容设置高度
    }
  }, [formData.rules]); // 监听规则内容的变化

  const handleSubmit = (e) => {
    e.preventDefault();
    // 对 formData 进行处理，确保没有 null 或 undefined 的字段
    const updatedFormData = {
      ...formData,
      rules: formData.rules || '0', // 如果 rules 为 null 或 undefined，设置为 '0'
      priority: formData.priority || 0,       // 如果 priority 为 null，设置为 0
      // 其他需要确保的字段
    };

    // 调用 onSave 函数并传递更新后的数据
    onSave(updatedFormData);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${year}-${month}-${day}`;
  };

  return (
    <div className='form-container'>
      <form onSubmit={handleSubmit}>
        <label>
          社交账户:
          <input type="text" name="socialAccount" value={formData.socialAccount} onChange={handleChange} />
        </label>
        <label>
          名:
          <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
        </label>
        <label>
          姓:
          <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
        </label>
        <label>
          邮箱:
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </label>
        <label>
          生日:
          <input type="date" name="birthday" value={formatDate(formData.birthday)} onChange={handleChange} />
        </label>
        <label>
          电话:
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
        </label>
        <label>
          期望递签日期:
          <input type="date" name="expectedDeliveryDate" value={formatDate(formData.expectedDeliveryDate)} onChange={handleChange} />
        </label>
        <label>
          优先级:
          <input
            type="number"
            name="priority"
            value={formData.priority || 0} // 默认值为0
            onChange={handleChange}
            step="1"
            min="0"
          />
        </label>
        <br></br>
        <label>
          约束规则:
          <textarea
            name="rules"
            value={formData.rules}
            onChange={handleChange}
            rows={1}
            style={{
              resize: 'none',
              overflow: 'hidden',
              width: '100%',
            }}
            onInput={(e) => {
              e.target.style.height = 'auto'; // 每次输入时先重置高度
              e.target.style.height = `${e.target.scrollHeight}px`; // 设置为内容的高度
            }}
          />
        </label>
        <label>
          订单状态:
          <select name="bookingStatus" value={formData.bookingStatus} onChange={handleChange}>
            <option value="未完成">未完成</option>
            <option value="已完成">已完成</option>
            <option value="已取消">已取消</option>
          </select>
        </label>
        <label>
          备注:
          <input type="text" name="note" value={formData.note} onChange={handleChange} />
        </label>
        <button className="custom-button" type="submit">保存</button>
        &nbsp;&nbsp;&nbsp; {/* 插入一个空格 */}
        <button className="custom-button" type="button" onClick={onCancel}>取消</button>
      </form>
    </div>
  );
};


export default UserManagementPage;
