import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'; // 引入样式文件

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post('http://92.119.127.236:8080/auth/login', null, {
        params: {
          username: username,
          password: password
        }
      });
      const token = response.data;
      localStorage.setItem('token', token); // 将token保存到本地存储
      navigate('/function-selector');
    } catch (error) {
      console.error('Login failed:', error);
      setError(error)
    }
  };

  return (
    <div className='login-container'>
      <h2>Login</h2>
      <div className='input-container'>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </div>
      <div className='input-container'>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      {error && <div className='error-message'>{error}</div>}
      <button className='login-button' onClick={handleLogin}>Login</button>
    </div>
  );
};

export default LoginPage;
