import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Messages.css";

const MessagesPage = () => {
  const [messages, setMessages] = useState([]); // 消息数据
  const [page, setPage] = useState(0); // 当前页码
  const [isLastPage, setIsLastPage] = useState(false); // 是否是最后一页
  const [isLoading, setIsLoading] = useState(false); // 加载状态
  const [searchContent, setSearchContent] = useState(""); // 搜索关键字
  const [debouncedSearchContent, setDebouncedSearchContent] = useState(""); // 防抖后的搜索关键字
  const loaderRef = useRef(null); // 观察器引用
  const initialLoad = useRef(false); // 初次加载标志

  // 防抖逻辑：延迟触发搜索关键字变化
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchContent(searchContent);
    }, 500); // 延迟 500ms
    return () => clearTimeout(timer);
  }, [searchContent]);

  // 加载消息数据
  const fetchMessages = async (currentPage, search = debouncedSearchContent) => {
    if (isLoading || isLastPage) return;
    setIsLoading(true);

    try {
      const token = localStorage.getItem("token"); // 获取 token
      const url = search
        ? `http://romi.one:8080/common/api/messages/search?tag=${search}&page=${currentPage}&size=30`
        : `http://romi.one:8080/common/api/messages?page=${currentPage}&size=30`;
      const response = await axios.get(url, {
        headers: {
          Authorization: token, // 添加 token 到请求头
        },
      });
      const data = response.data;

      // 更新数据和状态
      if (currentPage === 0) {
        setMessages(data.content); // 如果是第一页，替换数据
      } else {
        setMessages((prev) => [...prev, ...data.content]); // 如果是其他页，追加数据
      }
      setIsLastPage(data.last);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // 如果返回状态码是401，则跳转到登录页面
            window.location.href = 'http://92.119.127.236/login';
        } else {
            console.error("Error fetching messages:", error);
        }
    } finally {
      setIsLoading(false);
    }
  };

  // 监听搜索关键字变化并重新加载第一页数据
  useEffect(() => {
    if (initialLoad.current) {
      setPage(0); // 重置页码
      setIsLastPage(false); // 重置是否为最后一页
      fetchMessages(0, debouncedSearchContent); // 重新加载数据
    }
  }, [debouncedSearchContent]);

  // 处理分页加载逻辑
  useEffect(() => {
    if (page > 0) {
      fetchMessages(page);
    }
  }, [page]);

  // IntersectionObserver 实现自动加载
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading && !isLastPage) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [isLoading, isLastPage]);

  // 初次加载数据
  useEffect(() => {
    if (!initialLoad.current) {
      fetchMessages(page);
      initialLoad.current = true;
    }
  }, []);

  return (
    <div className="messages-container">
      <div className="search-box">
        <input
          type="text"
          placeholder="Search by tag..."
          value={searchContent}
          onChange={(e) => setSearchContent(e.target.value)}
        />
      </div>
      <div className="messages-table">
        <div className="messages-header">
          <div className="column">Created At</div>
          <div className="column">Tag</div>
          <div className="column">Message</div>
        </div>
        {messages.map((msg) => (
          <div key={msg.id} className="messages-row">
            <div className="column">{new Date(msg.createdAt).toLocaleString()}</div>
            <div className="column">{msg.tag}</div>
            <div className="column">{msg.message}</div>
          </div>
        ))}
      </div>
      {!isLastPage && <div className="loader" ref={loaderRef}>Loading...</div>}
    </div>
  );
};

export default MessagesPage;
